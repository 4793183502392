import AutocompleteBar from '../controllers/vue/AutocompleteBar.js';
import HomePostComponent from '../controllers/vue/home_post_collection.js';
import PostIndexComponent from '../controllers/vue/post_index_collection.js';
import ProfileCollectionComponent from '../controllers/vue/profile_collection.js';
import PostImageSlideComponent from '../controllers/vue/post_image_slide_component.js';
import PostRelatedPostsComponent from '../controllers/vue/post_related_posts_component.js';
import PostCommentsComponent from '../controllers/vue/post_comments_component.js';

import ProfileCartIndexComponent from '../controllers/vue/profile_cart_index.js';

import ProfilePageFollowComponent from '../controllers/vue/profile_page_follow_collection.js';
import ProfilePageModelComponent from '../controllers/vue/profile_page_model_collection.js';
import ProfilePageReviewComponent from '../controllers/vue/profile_page_review_collection.js';

import ProfileModelsComponent from '../controllers/vue/profile_models.js';
import ProfileLibrariesComponent from '../controllers/vue/profile_libraries.js';
import ProfileSortModelsComponent from '../controllers/vue/profile_sort_models.js';
import ProfileInboxComponent from '../controllers/vue/profile_inbox.js';

window.AutocompleteBar = AutocompleteBar
window.HomePostComponent = HomePostComponent
window.PostIndexComponent = PostIndexComponent
window.ProfileCollectionComponent = ProfileCollectionComponent
window.PostImageSlideComponent = PostImageSlideComponent
window.PostRelatedPostsComponent = PostRelatedPostsComponent
window.PostCommentsComponent = PostCommentsComponent

window.ProfileCartIndexComponent = ProfileCartIndexComponent

window.ProfilePageFollowComponent = ProfilePageFollowComponent
window.ProfilePageModelComponent = ProfilePageModelComponent
window.ProfilePageReviewComponent = ProfilePageReviewComponent

window.ProfileModelsComponent = ProfileModelsComponent
window.ProfileLibrariesComponent = ProfileLibrariesComponent
window.ProfileSortModelsComponent = ProfileSortModelsComponent
window.ProfileInboxComponent = ProfileInboxComponent
